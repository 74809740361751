/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/** @jsx jsx */

import {graphql} from 'gatsby'
import {jsx} from 'theme-ui'
import {Layout} from '../components'
import {Container} from '../elements'

const Impressum = ({data: {Impress}, location}) => (
  <Layout>
    <div
      sx={{
        mb: [(t) => t.layout.separation.mobile, '', '6rem'],
      }}
    >
      <div
        sx={{
          backgroundColor: 'primary',
          p: ['7rem 1.5rem 5rem 1.5rem', '', '12rem 1.5rem 8rem 1.5rem'],
        }}
      >
        <div
          sx={{
            maxWidth: '70rem',
            m: '0 auto',
          }}
        >
          <h1
            sx={{
              fontSize: ['2rem', '', '2.5rem'],
              lineHeight: 1.25,
              color: (t) => t.colors.gray[1],
              m: 0,
            }}
          >
            {Impress.data.header_title}
          </h1>
          <hr
            sx={{
              backgroundColor: '#fff',
              height: '4px',
              width: ['17rem', '', '12rem'],
              margin: '0.25rem 0 0 0',
              padding: '0',
              border: 0,
            }}
          />
        </div>
      </div>
    </div>

    <Container>
      <div
        sx={{
          p: {
            fontSize: '1.25rem',
          },
          mb: '2rem',
        }}
        dangerouslySetInnerHTML={{__html: Impress.data.content.html}}
      />
    </Container>
  </Layout>
)

export const query = graphql`
  query Impressum {
    Impress: prismicImpressum {
      data {
        header_title
        content {
          html
        }
      }
    }
  }
`

export default Impressum
